import axios from '@axios';

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchVisits(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				axios
					.get('/visits/bidders', { params: queryParams })
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		createVisit(ctx, payload) {
			return new Promise((resolve, reject) => {
				axios
					.post('/visits', payload)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		deleteVisit(ctx, { id, payload }) {
			console.log(payload)
			return new Promise((resolve, reject) => {
				axios
					.delete(`/visits/${id}?tender_id=${payload.tender_id}&competition_id=${payload.competition_id}`)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
	}
}