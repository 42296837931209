<template>
	<div>
		<b-card-countdown
			stage="visita en campo"
			:stage-closed="stageClosed"
			:deadline="deadline"
			:start-date="startDate"
			@time-finished="handleTimeFinished"
			@not-yet-started="handleNotYetStarted"
		/>

		<visit-list-add-new
			:is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
			:competition-id="competitionId"
			:tender-id="tenderId"
			@refetch-data="refetchData"
		/>

		<!-- Table Container Card -->
		<b-card
			no-body
			class="mb-0"
		>
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<!-- Per Page -->
					<b-col
						cols="12"
						md="4"
						class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
					>
						<label>Mostrar</label>
						<v-select
							v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label>registros</label>
					</b-col>

					<!-- Search -->
					<b-col
						cols="12"
						md="6"
						class="ml-auto"
					>
						<div class="d-flex">
							<b-form-input
								v-model="searchQuery"
								class="d-inline-block mr-1"
								placeholder="Buscar ..."
								trim
							/>
							<b-button
								variant="primary"
								@click="isAddNewUserSidebarActive = true"
								:disabled="isDisabled"
							>
								<span class="text-nowrap">Agregar Visitante</span>
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div>
			<!-- 
			:style="totalVisits >= 1 && totalVisits <= 3 ? 'height: 280px' : 'height: inherit'"-->
			<b-table
				ref="refTenderBidderVisitsListTable"
				class="position-relative table-tenders"
				:items="fetchVisits"
				responsive
				:fields="tableColumns"
				primary-key="id2"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No se encontraron registros coincidentes"
				:sort-desc.sync="isSortDirDesc"
			>
				<template #table-busy>
					<div class="text-center text-primary my-2">
						<b-spinner class="align-middle mb-1"></b-spinner>
						<div>
							<b>CARGANDO</b>
						</div>
					</div>
				</template>

				<template #cell(fullname)="data">
					<b-media vertical-align="center">
						<template #aside>
							<b-avatar
								size="32"
								:src="null"
								:text="avatarText(data.item.fullname)"
								variant="light-success"
							/>
						</template>
						<b-link class="font-weight-bold d-block text-nowrap">{{ data.item.fullname }}</b-link>
					</b-media>
				</template>

				<template #cell(dni)="data">
					<div class="text-nowrap">{{ data.item.dni }}</div>
				</template>

				<template #cell(position)="data">
					<div class="text-nowrap">{{ data.item.position }}</div>
				</template>

				<template #cell(documents)="data">
					<div class="text-nowrap">
						<b-button-upload-file
							:tender-id="tenderId"
							:competition-id="competitionId"
							:is-disabled="isDisabled"
							:button-props="{ size: 'sm', icon: 'PaperclipIcon', text: `${isDisabled ? 'VER' : 'CARGAR'} DOCUMENTOS` }"
							:modal-props="{ title: 'DOCUMENTOS DE VISITA' }"
							:visit-props="{ visitId: data.item.id, isVisit: true }"
						/>
					</div>
				</template>

				<template #cell(actions)="data">
					<b-dropdown
						variant="link"
						no-caret
						:right="$store.state.appConfig.isRTL"
					>
						<template #button-content>
							<feather-icon
								icon="MoreVerticalIcon"
								size="16"
								class="align-middle text-body"
							/>
						</template>

						<b-dropdown-item
							@click="deleteVisitor(data.item.id)"
							:disabled="isDisabled"
						>
							<feather-icon icon="TrashIcon" />
							<span class="align-middle ml-50">Eliminar</span>
						</b-dropdown-item>
					</b-dropdown>
				</template>
			</b-table>

			<div class="mx-2 mb-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span
							class="text-muted"
						>Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
					</b-col>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="currentPage"
							:total-rows="totalVisits"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
	</div>
</template>

<script>
import BButtonUploadFile from "@core/components/b-button-upload-file/BButtonUploadFile.vue"
import BCardCountdown from "@core/components/b-card-countdown/BCardCountdown"
import { ref, reactive, onBeforeMount, onUnmounted } from "@vue/composition-api"
import { Stages, CompetitionStates } from "@core/utils/data"
import useVisitList from "./useTenderEditTabVisitsList"
import visitStoreModule from "./visitStoreModule"
import VisitListAddNew from "./TabVisitsListAddNew"
import { avatarText } from "@core/utils/filter"
import useNotifications from "@notifications"
import VueSweetalert2 from "vue-sweetalert2"
import "vue2-dropzone/dist/vue2Dropzone.min.css"
import vSelect from "vue-select"
import store from "@/store"
import moment from "moment"
import Vue from "vue"
import "animate.css"
moment.locale("es")

Vue.use(VueSweetalert2)

export default {
	components: {
		BButtonUploadFile,
		BCardCountdown,
		VisitListAddNew,

		vSelect
	},
	data() {
		return {
			moment
		}
	},
	props: {
		competitionId: {
			type: Number,
			required: true
		},
		tenderId: {
			type: Number,
			required: true
		},
		scheduleData: {
			type: Array,
			required: true
		},
		competitionData: {
			type: Object,
			required: true
		}
	},
	setup(props) {
		// USE TOAST
		const { toastNotification, swalNotification } = useNotifications()
		const { EN_CURSO } = CompetitionStates

		// REFS
		const tenderId = ref(props.tenderId)
		const competitionId = ref(props.competitionId)
		const scheduleData = ref(props.scheduleData)
		const competitionData = ref(props.competitionData)
		const options = ref({})
		const deadline = ref(null)
		const startDate = ref(null)
		const isAddNewUserSidebarActive = ref(false)
		const isDisabled = ref(false)
		const stageClosed = ref(false)

		// PROPS
		const buttonProps = reactive({
			size: "sm",
			icon: "PaperclipIcon",
			text: "CARGAR DOCUMENTOS"
		})

		const VISIT_APP_STORE_MODULE_NAME = "app-visit"

		// REGISTER MODULE
		if (!store.hasModule(VISIT_APP_STORE_MODULE_NAME))
			store.registerModule(VISIT_APP_STORE_MODULE_NAME, visitStoreModule)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(VISIT_APP_STORE_MODULE_NAME))
				store.unregisterModule(VISIT_APP_STORE_MODULE_NAME)
		})

		// CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
		onBeforeMount(() => {
			setData()
		})

		if (competitionData.value.competition_state_id !== EN_CURSO) {
			stageClosed.value = true
			isDisabled.value = true
		}

		const setData = () => {
			const visit = scheduleData.value.find(
				(item) => parseInt(item.stage_id) === Stages.VISITA
			)
			startDate.value = visit.actual_start_date || ""
			deadline.value = visit.actual_end_date || ""
		}

		const getVisitProps = (item) => {
			return {
				visitId: item.id,
				isVisit: true
			}
		}

		const handleTimeFinished = () => {
			stageClosed.value = true
			isDisabled.value = true
		}
		const handleNotYetStarted = (value) => {
			if (competitionData.value.competition_state_id !== EN_CURSO) {
				stageClosed.value = true
				isDisabled.value = true
			} else {
				isDisabled.value = value
				stageClosed.value = value
			}
		}

		const deleteVisitor = (id) => {
			Vue.swal({
				title: "¿Está seguro(a) de eliminar al visitante?",
				html: "<small>¡Si no lo está, puede cancelar la acción!</small>",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sí, eliminar!",
				cancelButtonText: "Cancelar",
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1"
				},
				showClass: {
					popup: "animate__animated animate__tada"
				},
				buttonsStyling: false
			}).then((result) => {
				if (result.value) {
					store
						.dispatch("app-visit/deleteVisit", {
							id,
							payload: {
								tender_id: tenderId.value,
								competition_id: competitionId.value
							}
						})
						.then((response) => {
							refetchData()
							swalNotification(response.data.message)
						})
						.catch((error) => {
							const message =
								error.response.data.message || "Error al eliminar al visitante"
							swalNotification(message, false)
						})
				}
			})
		}

		const {
			fetchVisits,
			tableColumns,
			perPage,
			currentPage,
			totalVisits,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refTenderBidderVisitsListTable,
			refetchData
		} = useVisitList(props.competitionId)

		return {
			fetchVisits,
			tableColumns,
			options,
			perPage,
			currentPage,
			totalVisits,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refTenderBidderVisitsListTable,
			refetchData,
			isDisabled,
			stageClosed,
			deadline,
			startDate,

			// ACTIONS
			avatarText,
			deleteVisitor,
			handleTimeFinished,
			handleNotYetStarted,

			// REFS
			isAddNewUserSidebarActive,
			Stages,

			//PROPS
			buttonProps,
			getVisitProps
		}
	}
}
</script>

<style lang="scss" scoped>
.media {
	align-items: center;
}

.per-page-selector {
	width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>