<template>
	<div>
		<b-card
			footer-tag="footer"
			class="mb-0"
		>
			<b-row>
				<b-col
					cols="12"
					class="d-flex justify-content-between align-items-center mb-2"
				>
					<label>
						<strong>CRONOGRAMA DE ETAPAS</strong>
					</label>
				</b-col>

				<b-col cols="12">
					<b-table
						ref="refScheduleTable"
						responsive
						:fields="fields"
						:items="items"
						bordered
					>
						<template #cell(actual_start_date)="data">
							<div
								class="text-nowrap"
								:class="{ 'text-muted': !data.item.actual_start_date }"
							>{{ data.item.actual_start_date || 'DD-MM-YYYY' }}</div>
						</template>

						<template #cell(actual_end_date)="data">
							<div
								class="text-nowrap"
								:class="{ 'text-muted': !data.item.actual_end_date }"
							>{{ data.item.actual_end_date || 'DD-MM-YYYY' }}</div>
						</template>
					</b-table>
				</b-col>
			</b-row>
		</b-card>
	</div>
</template>

<script>
import { ref, onBeforeMount } from "@vue/composition-api"
import moment from "moment"

export default {
	data() {
		return {
			moment
		}
	},
	props: {
		scheduleData: {
			type: Array,
			required: true
		}
	},
	setup(props) {
		const scheduleData = ref(props.scheduleData)
		const items = ref([])
		const fields = ref([])

		onBeforeMount(() => {
			setData()
		})

		const setData = () => {
			const styleColumns = { thClass: "text-center", tdClass: "text-center" }

			fields.value = [
				{ key: "name", label: "Etapa", thClass: styleColumns.thClass },
				{ key: "actual_start_date", label: "Fecha Inicial", ...styleColumns },
				{ key: "actual_end_date", label: "Fecha Final", ...styleColumns }
			]
			items.value = scheduleData.value.map((item) => ({
				actual_start_date: item.actual_start_date
					? moment(item.actual_start_date).format("DD-MM-YYYY H:mm")
					: null,
				actual_end_date: item.actual_end_date
					? moment(item.actual_end_date).format("DD-MM-YYYY H:mm")
					: null,
				name: item.stage_name
			}))
		}

		return {
			items,
			fields
		}
	}
}
</script>