<template>
	<div>
		<b-form>
			<b-row>
				<b-col md="12">
					<b-card
						footer-tag="footer"
						class="mb-0"
					>
						<b-row>
							<b-col
								cols="12"
								class="my-1"
							>
								<b-alert
									variant="info"
									show
								>
									<h6 class="alert-heading">
										<feather-icon
											icon="InfoIcon"
											class="mr-50"
										/>
										<span
											class="small"
										>La ejecución del servicio se dará acabo en la(s) unidad(es) seleccionada(s)</span>
									</h6>
								</b-alert>
							</b-col>

							<!-- TENDER TITLE -->
							<b-col
								cols="12"
								md="12"
								class="mb-2"
							>
								<label for="title">
									<strong>OBJETO DEL CONTRATO</strong>
								</label>
								<b-form-input
									id="title"
									v-model="tenderData.title"
									disabled
									readonly
								/>
							</b-col>

							<!-- Deadline -->
							<b-col
								cols="8"
								class="mb-2"
							>
								<label for="type">
									<strong>TIPO DE CONTRATO</strong>
								</label>
								<b-form-input
									id="type"
									:value="tenderData.contract_name"
									disabled
									readonly
								/>
							</b-col>
							<b-col
								cols="4"
								class="mb-2"
							>
								<label for="service">
									<strong>EJECUCIÓN DE SERVICIO</strong>
								</label>
								<v-select
									id="location"
									:value="tenderData.locations"
									:options="options.locations"
									label="name"
									multiple
									disabled
									readonly
								/>
							</b-col>

							<!-- DESCRIPTION -->
							<b-col
								cols="12"
								class="mb-2"
							>
								<label for="description">
									<strong>DESCRIPCIÓN</strong>
								</label>
								<b-form-textarea
									id="description"
									:value="tenderData.description"
									rows="5"
									disabled
									readonly
								></b-form-textarea>
							</b-col>

							<!-- FILES -->
							<b-col
								cols="12"
								md="6"
								class="mb-2"
							>
								<label>
									<strong>DOCUMENTOS GENERALES</strong>
								</label>
								<b-button-upload-file
									:tender-id="tenderData.id"
									:is-published="tenderData.is_published"
									:tender-props="{ isGeneral: true }"
									:modal-props="{ title: 'DOCUMENTOS GENERALES' }"
									:button-props="{ icon: 'PaperclipIcon', text: 'VER DOCUMENTOS', block : true }"
								/>
							</b-col>

							<b-col
								cols="12"
								md="6"
								class="mb-2"
							>
								<label for="dropzone">
									<strong>DOCUMENTOS ESPECÍFICOS</strong>
								</label>
								<b-button-upload-file
									:is-disabled="isDisabled"
									:competition-id="competitionId"
									:is-published="tenderData.is_published"
									:tender-props="{ isSpecific: true }"
									:modal-props="{ title: 'DOCUMENTOS ESPECÍFICOS' }"
									:button-props="{ icon: 'PaperclipIcon', text: `${isDisabled ? 'VER' : 'CARGAR'} DOCUMENTOS`, block : true }"
								/>
							</b-col>
						</b-row>
					</b-card>
				</b-col>
			</b-row>
		</b-form>
	</div>
</template>

<script>
import { CompetitionStates } from "@core/utils/data"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import BButtonUploadFile from "@core/components/b-button-upload-file/BButtonUploadFile.vue"
import { ref, onBeforeMount } from "@vue/composition-api"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import Ripple from "vue-ripple-directive"
import vSelect from "vue-select"
import axios from "@axios"

export default {
	components: {
		BButtonLoading,
		BButtonUploadFile,

		ValidationObserver,
		ValidationProvider,

		vSelect
	},
	directives: {
		Ripple
	},
	props: {
		competitionId: {
			type: Number,
			required: true
		},
		tenderData: {
			type: Object,
			required: true
		},
		competitionData: {
			type: Object,
			required: true
		}
	},
	setup(props) {
		// REFS
		const refForm = ref(null)
		const { FINALIZADO } = CompetitionStates

		const formData = ref({
			title: null,
			description: null,
			deadline_date: null,
			is_published: 0
		})

		const competitionData = ref(props.competitionData)
		const processing = ref(false)
		const isDisabled = ref(false)
		const options = ref({})

		onBeforeMount(async () => {
			await getSelectFilters()
		})

		const getSelectFilters = async () => {
			const response = await axios.get("/selectors/locations")
			options.value = {
				...response.data
			}
		}

		if (
			competitionData.value.competition_state_id !== CompetitionStates.EN_CURSO
		) {
			isDisabled.value = true
		}

		if (competitionData.value.competition_state_id === FINALIZADO) {
			isDisabled.value = true
		}

		return {
			// DATA
			processing,
			formData,
			options,
			isDisabled,

			// REFS
			refForm
		}
	}
}
</script>

<style lang="scss" scoped>
.description-content {
	min-height: 150px;
	overflow-y: auto;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>