<template>
	<b-sidebar
		id="add-new-user-sidebar"
		:visible="isAddNewUserSidebarActive"
		bg-variant="white"
		sidebar-class="sidebar-lg"
		shadow
		backdrop
		no-header
		right
		@hidden="resetForm"
		@change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
	>
		<template #default="{ hide }">
			<!-- Header -->
			<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
				<h5 class="mb-0">
					<strong>AGREGAR VISITANTE</strong>
				</h5>

				<feather-icon
					class="ml-1 cursor-pointer"
					icon="XIcon"
					size="16"
					@click="hide"
				/>
			</div>

			<!-- BODY -->
			<validation-observer
				#default="{ handleSubmit }"
				ref="refFormObserver"
			>
				<!-- Form -->
				<b-form
					class="p-2"
					@submit.prevent="handleSubmit(onSubmit)"
					@reset.prevent="resetForm"
				>
					<!-- Name -->
					<validation-provider
						#default="validationContext"
						name="nombre completo"
						rules="required"
					>
						<b-form-group
							label="NOMBRE COMPLETO"
							label-for="fullname"
						>
							<b-form-input
								id="fullname"
								v-model="userData.fullname"
								:state="getValidationState(validationContext)"
								trim
							/>

							<b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- Ruc -->
					<validation-provider
						#default="validationContext"
						name="dni"
						rules="required"
					>
						<b-form-group
							label="DNI"
							label-for="dni"
						>
							<b-form-input
								id="dni"
								v-model="userData.dni"
								:state="getValidationState(validationContext)"
								trim
							/>

							<b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- Ruc -->
					<validation-provider
						#default="validationContext"
						name="puesto"
						rules="required"
					>
						<b-form-group
							label="PUESTO"
							label-for="puesto"
						>
							<b-form-input
								id="puesto"
								v-model="userData.position"
								:state="getValidationState(validationContext)"
								trim
							/>

							<b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- Form Actions -->
					<div class="d-flex mt-2">
						<b-button
							v-ripple.400="'rgba(255, 255, 255, 0.15)'"
							variant="primary"
							class="mr-2"
							type="submit"
						>AGREGAR</b-button>
						<b-button
							v-ripple.400="'rgba(186, 191, 199, 0.15)'"
							type="button"
							variant="outline-secondary"
							@click="hide"
						>CANCELAR</b-button>
					</div>
				</b-form>
			</validation-observer>
		</template>
	</b-sidebar>
</template>

<script>
import useNotifications from "@notifications"
import { BSidebar } from "bootstrap-vue"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { ref } from "@vue/composition-api"
import { required } from "@validations"
import formValidation from "@core/comp-functions/forms/form-validation"
import Ripple from "vue-ripple-directive"
import store from "@/store"

export default {
	components: {
		BSidebar,

		// Form Validation
		ValidationProvider,
		ValidationObserver
	},
	directives: {
		Ripple
	},
	model: {
		prop: "isAddNewUserSidebarActive",
		event: "update:is-add-new-user-sidebar-active"
	},
	props: {
		isAddNewUserSidebarActive: {
			type: Boolean,
			required: true
		},
		competitionId: {
			type: Number
		},
		tenderId: {
			type: Number
		}
	},
	data() {
		return {
			required
		}
	},
	setup(props, { emit }) {
		// Use toast
		const { toastNotification } = useNotifications()

		const tenderId = ref(props.tenderId)
		const competitionId = ref(props.competitionId)

		const blankUserData = {
			fullname: "",
			dni: "",
			position: ""
		}

		const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
		const resetuserData = () => {
			userData.value = JSON.parse(JSON.stringify(blankUserData))
		}

		const onSubmit = () => {
			let visitData = {
				...userData.value,
				tender_id: tenderId.value,
				competition_id: competitionId.value
			}

			store
				.dispatch("app-visit/createVisit", visitData)
				.then((response) => {
					emit("refetch-data")
					toastNotification(response.data.message)
					emit("update:is-add-new-user-sidebar-active", false)
				})
				.catch((error) => {
					const message =
						error.response.data.message || "Error al agregar al visitante"
					toastNotification(message, false)
				})
		}

		const { refFormObserver, getValidationState, resetForm } =
			formValidation(resetuserData)

		return {
			userData,
			onSubmit,

			refFormObserver,
			getValidationState,
			resetForm
		}
	}
}
</script>

<style lang="scss">
#add-new-user-sidebar {
	.vs__dropdown-menu {
		max-height: 200px !important;
	}
}
</style>
