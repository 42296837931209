<template>
	<section>
		<b-form>
			<div class="table-responsive">
				<table class="content-table table table-bordered text-center w-100">
					<thead>
						<tr>
							<th
								class="py-2"
								colspan="12"
							>CATEGORÍAS REQUERIDAS PARA LA PROPUESTA DE SEGURIDAD, CALIDAD, MEDIO AMBIENTE Y ANTI CORRUPCIÓN</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="(document, index) in sigDocuments"
							:key="index"
						>
							<td class="col-10">
								<label>{{ index + 1 }}</label>
							</td>
							<td class="col-20 text-left">
								<label>{{ document.name }}</label>
							</td>

							<td class="col-10">
								<b-form-radio
									v-model="document.proposal_option_id"
									:name="'sig-radios-' + index"
									:value="1"
									:disabled="isDisabled"
									:readonly="isDisabled"
								>SÍ</b-form-radio>
							</td>
							<td class="col-10">
								<b-form-radio
									v-model="document.proposal_option_id"
									:name="'sig-radios-' + index"
									:value="2"
									:disabled="isDisabled"
									:readonly="isDisabled"
								>PARCIAL</b-form-radio>
							</td>
							<td class="col-10">
								<b-form-radio
									v-model="document.proposal_option_id"
									:name="'sig-radios-' + index"
									:value="3"
									:disabled="isDisabled"
									:readonly="isDisabled"
								>NO</b-form-radio>
							</td>
							<td class="col-10">
								<b-form-radio
									v-model="document.proposal_option_id"
									:name="'sig-radios-' + index"
									:value="4"
									:disabled="isDisabled"
									:readonly="isDisabled"
								>N.A.</b-form-radio>
							</td>
							<td class="col-20">
								<b-button-upload-file
									:is-disabled="isDisabled"
									:competition-id="competitionId"
									:button-props="{size: 'sm', icon: 'PaperclipIcon', text: `${ isDisabled ? 'VER' : 'CARGAR'} DOCUMENTOS`}"
									:modal-props="{ title: 'DOCUMENTOS DE PROPUESTA DE SEGURIDAD, CALIDAD, MEDIO AMBIENTE Y ANTI CORRUPCIÓN' }"
									:proposal-props="{isSig: true, proposalId: document.id }"
								/>
							</td>
							<td class="col-10">
								<b-button
									v-ripple.400="'rgba(113, 102, 240, 0.15)'"
									variant="outline-primary"
									class="btn-icon rounded-circle"
									@click="openCommentModal(document.id)"
								>
									<feather-icon icon="MessageSquareIcon" />
								</b-button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<br />
			<b-button-loading
				text="GUARDAR CAMBIOS"
				variant="outline-primary"
				:block="true"
				:processing="processing"
				:disabled="isDisabled"
				@process-action="saveInformation"
			/>
		</b-form>

		<b-modal
			ref="refCommentModal"
			title-tag="div"
			header-bg-variant="primary"
			header-text-variant="white"
			centered
			title="COMENTARIO"
			no-close-on-backdrop
			no-close-on-esc
			hide-header-close
		>
			<b-form>
				<b-col
					cols="12"
					class="my-2"
				>
					<b-form-group>
						<b-form-textarea
							id="comment"
							v-model="comment"
							placeholder="Comente aquí ..."
							:disabled="isDisabled"
							:readonly="isDisabled"
							rows="4"
						/>
					</b-form-group>
				</b-col>
			</b-form>

			<template #modal-footer="{ cancel }">
				<b-button
					@click="saveCommentModal"
					variant="primary"
					:disabled="isDisabled"
				>GUARDAR</b-button>

				<b-button
					v-ripple.400="'rgba(186, 191, 199, 0.15)'"
					type="button"
					variant="outline-secondary"
					class="ml-2"
					@click="cancel"
				>CERRAR</b-button>
			</template>
		</b-modal>
	</section>
</template>

<script>
import { ref, onBeforeMount } from "@vue/composition-api"
import BCardCountdown from "@core/components/b-card-countdown/BCardCountdown"
import BButtonUploadFile from "@core/components/b-button-upload-file/BButtonUploadFile.vue"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import BIconFile from "@core/components/b-icon-file/BIconFile.vue"
import Ripple from "vue-ripple-directive"
import { VBModal } from "bootstrap-vue"
import axios from "@axios"
import useNotifications from "@notifications"
export default {
	components: {
		BCardCountdown,
		BButtonLoading,
		BButtonUploadFile,
		BIconFile
	},
	directives: {
		"b-modal": VBModal,
		Ripple
	},
	props: {
		competitionId: {
			type: Number,
			required: true
		},
		tenderId: {
			type: Number,
			required: true
		},
		proposalsData: {
			type: Array,
			default: () => [],
			required: true
		},
		isDisabled: {
			type: Boolean,
			required: true
		}
	},
	setup(props) {
		const { toastNotification } = useNotifications()

		const tenderId = ref(props.tenderId)
		const competitionId = ref(props.competitionId)
		const proposalsData = ref(props.proposalsData)

		const processing = ref(false)

		const refCommentModal = ref(null)
		const comment = ref(null)

		const SIG_ID = 2
		const sigDocuments = ref([])
		const proposalId = ref(null)

		const openCommentModal = (id) => {
			proposalId.value = id
			comment.value = sigDocuments.value.find((item) => item.id === id).comment

			refCommentModal.value.show()
		}

		onBeforeMount(() => {
			setData()
		})

		const setData = () => {
			sigDocuments.value = proposalsData.value
				.filter((item) => parseInt(item.tender_document_type) === SIG_ID)
				.map((item) => ({
					id: item.id,
					name: item.tender_document_name,
					proposal_option_id: item.proposal_option_id,
					comment: item.comment
				}))
		}

		const saveCommentModal = async () => {
			try {
				const payload = {
					tender_id: tenderId.value,
					competition_id: competitionId.value,
					comment: comment.value
				}
				const response = await axios.put(
					`/proposals/${proposalId.value}`,
					payload
				)
				toastNotification(response.data.message)

				sigDocuments.value.forEach((doc) => {
					if (doc.id === proposalId.value) {
						doc.comment = comment.value
					}
				})

				proposalId.value = null
			} catch (error) {
				toastNotification(error.response.data.message, false)
			} finally {
				refCommentModal.value.hide()
			}
		}

		const saveInformation = async () => {
			try {
				processing.value = true

				const selectedOptions = sigDocuments.value.map((doc) => ({
					id: doc.id,
					proposal_option_id: doc.proposal_option_id
				}))
				const payload = {
					tender_id: tenderId.value,
					competition_id: competitionId.value,
					selectedOptions
				}

				const response = await axios.put(`/proposals`, payload)

				toastNotification(response.data.message)
			} catch (error) {
				toastNotification(error.response.data.message, false)
			} finally {
				processing.value = false
			}
		}

		return {
			refCommentModal,
			processing,
			comment,
			sigDocuments,

			openCommentModal,
			saveCommentModal,
			saveInformation
		}
	}
}
</script>

<style scoped>
.col-10 {
	width: 10%;
}

.col-60 {
	width: 60%;
}

.col-20 {
	width: 30%;
}
</style>